import React, { useState, useEffect, useRef } from 'react';
import './UpdateMachineModal.css';

const UpdateMachineModal = ({ deliveryData, imageTaskDetails, onClose, onSubmit }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [updatedDeliveryData, setUpdatedDeliveryData] = useState(deliveryData);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const zoomRef = useRef(null);
  

  const getImageUrls = (item) => {
    let imageUrls = [];
    if (item.on_machine_image === 'LIST') {
      const imageDetails = imageTaskDetails.filter(detail => detail.task_id === item.task_id);
      imageUrls = imageDetails.map(detail => detail.on_machine_image);
    } else if (item.on_machine_image === 'N/A') {
      imageUrls = [];
    } else {
      imageUrls = [item.on_machine_image];
    }
    return imageUrls;
  };

  useEffect(() => {
    
    let initialIndex = 0;
    while (initialIndex < deliveryData.length && getImageUrls(deliveryData[initialIndex]).length === 0) {
      initialIndex++;
    }
    if (initialIndex < deliveryData.length) {
      setCurrentIndex(initialIndex);
      setCurrentImageIndex(0);
    }
  }, [deliveryData, imageTaskDetails]);

  const handleNext = () => {
    const currentTaskId = deliveryData[currentIndex].task_id;
    const imageUrls = getImageUrls(deliveryData[currentIndex]);
  
    if (currentImageIndex < imageUrls.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    } else {
      let newIndex = currentIndex;
      do {
        newIndex++;
      } while (newIndex < deliveryData.length && getImageUrls(deliveryData[newIndex]).length === 0);
      if (newIndex < deliveryData.length) {
        setCurrentIndex(newIndex);
        setCurrentImageIndex(0);
      }
    }
  };

  const handlePrevious = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    } else {
      let newIndex = currentIndex;
      do {
        newIndex--;
      } while (newIndex >= 0 && getImageUrls(deliveryData[newIndex]).length === 0);
      if (newIndex >= 0) {
        setCurrentIndex(newIndex);
        const imageUrls = getImageUrls(deliveryData[newIndex]);
        setCurrentImageIndex(imageUrls.length - 1);
      }
    }
  };

  const handleInputChange = (e, taskId) => {
    const { value } = e.target;
    setUpdatedDeliveryData(prevData =>
      prevData.map(item =>
        item.task_id === taskId ? { ...item, on_machine_tip: value } : item
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(updatedDeliveryData);
    onClose();
  };

  const currentItem = updatedDeliveryData[currentIndex] || {};
  const imageUrls = getImageUrls(currentItem);
  const imageUrl = imageUrls[currentImageIndex] || 'N/A';

  const hasPreviousValidItem = () => {
    if (currentImageIndex > 0) return true;
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (getImageUrls(updatedDeliveryData[i]).length > 0) {
        return true;
      }
    }
    return false;
  };

  const hasNextValidItem = () => {
    if (currentImageIndex < getImageUrls(currentItem).length - 1) return true;
    for (let i = currentIndex + 1; i < updatedDeliveryData.length; i++) {
      if (getImageUrls(updatedDeliveryData[i]).length > 0) {
        return true;
      }
    }
    return false;
  };

  const handleMouseMove = (e) => {
    const zoom = zoomRef.current;
    const img = e.target;
    const rect = img.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const xPercent = (x / rect.width) * 100;
    const yPercent = (y / rect.height) * 100;

    zoom.style.transformOrigin = `${xPercent}% ${yPercent}%`;
  };

  return (
    <>
      <div className="update-machine-overlay" onClick={onClose}></div>
      <div className="update-machine-modal">
        <form onSubmit={handleSubmit}>
          <div className="machine-item">
            <div className="counter-text text-left">
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ marginLeft: '10px' }}><span className="font-weight-bold">Task ID:</span> {currentItem.task_id}</div>
                <div style={{ marginRight: '10px' }}><span className="font-weight-bold">Order ID:</span> {currentItem.order_id}</div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ marginLeft: '10px' }}><span className="font-weight-bold">Store:</span> {currentItem.store_name}</div>
                <div style={{ marginRight: '10px' }}><span className="font-weight-bold">Agent:</span> {currentItem.agent_name}</div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ marginLeft: '10px' }}><span className="font-weight-bold">Customer:</span> {currentItem.customer_name}</div>
                <div style={{ marginRight: '10px' }}><span className="font-weight-bold">Completed On:</span> {currentItem.completion_time}</div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ marginLeft: '10px' }}><span className="font-weight-bold">Notes:</span> {currentItem.notes}</div>
                
              </div>
            </div>
            {imageUrl !== 'N/A' && (
              <div className="image-container">
                <img 
                  src={imageUrl} 
                  className="zoom-image" alt="Machine" 
                  style={{ maxWidth: 'auto', height: '450px', display: 'block', margin: '0 auto' }}
                  ref={zoomRef}
                  onMouseMove={handleMouseMove}
                />
                <div className="lens" id="lens"></div>
              </div>
            )}
            <br />
            <input type="hidden" name={`task_id_${currentItem.task_id}`} value={currentItem.task_id} />
            Updated Machine Tip: <input type="text" name={`machine_tip_${currentItem.task_id}`} value={currentItem.on_machine_tip} onChange={(e) => handleInputChange(e, currentItem.task_id)} />
          </div>
          <div className="text-center" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button className="btn btn-xsm bg-gradient-info w-100 mb-0 toast-btn" type="button" onClick={handlePrevious} disabled={!hasPreviousValidItem()}>Previous</button>
            <button className="btn btn-xsm bg-gradient-primary w-100 mb-0 toast-btn" type="submit">Submit</button>
            <button className="btn btn-xsm bg-gradient-info w-100 mb-0 toast-btn" type="button" onClick={handleNext} disabled={!hasNextValidItem()}>Next</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateMachineModal;