import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { API_ENDPOINTS } from '../settings.js';
import Notification, { notifyInfo, notifySuccess, notifyWarning, notifyError } from '../popups/Notification';
import ClipLoader from 'react-spinners/ClipLoader';
import UpdateMachineModal from './UpdateMachineModal';
import Modal from 'react-modal';
Modal.setAppElement('#root');

const InvoiceDataGenerator = () => {
  const [orderToProcess, setOrderToProcess] = useState(0);
  const [maxDate, setMaxDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deliveryData, setDeliveryData] = useState([]);
  const [imageTaskDetails, setImageTaskDetails] = useState([]);
  const [storeSummary, setStoreSummary] = useState([]);
  const [stores, setStores] = useState([]);
  const [editingStoreId, setEditingStoreId] = useState(null);
  const [editedSummary, setEditedSummary] = useState({});
  const [dateRestrictionsEnabled, setDateRestrictionsEnabled] = useState(true);
  let [authTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
  
  useEffect(() => {
    invoiceGeneratorData();
  }, [authTokens]);
  const invoiceGeneratorData = async () => {
    
    try {
      let response = await fetch(API_ENDPOINTS.INVOICE_DATA_GENERATOR, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        }
      });
      let data = await response.json();
      setOrderToProcess(data.order_to_process);
      setMaxDate(data.max_date);
      setDeliveryData(data.delivery_data);
      setImageTaskDetails(data.image_task_details);
      setStoreSummary(data.store_summary);
      setStores(data.stores);
    } catch (error) {
      notifyError("Failed to fetch data.");
    } 
  }
  const handlePullData = async () => {
    setIsLoading(true);
    let startDate1 = document.getElementById('startDate1').value;
    let endDate1 = document.getElementById('endDate1').value;
    let keyword = 'api_files' 

    if (!startDate1 || !endDate1) {
      notifyWarning("Please select both start and end dates.");
      setIsLoading(false);
      return;
    }
    try {
      let response = await fetch(API_ENDPOINTS.INVOICE_DATA_GENERATOR, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        },
        body: JSON.stringify({startDate1, endDate1, keyword})
      });
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to fetch data from server');
      }
      notifySuccess(responseData.success);
      invoiceGeneratorData();
      notifyInfo('Update Machine Tip Before Creating Store Summary');
    } catch (error) {
      notifyError(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModelSubmit = async (updatedDeliveryData) => {
    let keyword = 'update_machine_tip';
    let machine_tips = updatedDeliveryData.map(item => ({
      task_id: item.task_id,
      machine_tip: item.on_machine_tip
    }));
  
    try {
      let response = await fetch(API_ENDPOINTS.INVOICE_DATA_GENERATOR, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        },
        body: JSON.stringify({ keyword, machine_tips })
      });
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to update machine tips');
      }
      notifySuccess(responseData.success);
      invoiceGeneratorData();
    } catch (error) {
      notifyError(`Error: ${error.message}`);
    }
  };
  const handleProcessStoreSummary = async () => {
    let keyword = 'store_summary';
    try {
      let response = await fetch(API_ENDPOINTS.INVOICE_DATA_GENERATOR, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        },
        body: JSON.stringify({ keyword })
      });
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to update Store Summary');
      }
      notifySuccess(responseData.success);
      invoiceGeneratorData();
    } catch (error) {
      notifyError(`Error: ${error.message}`);
    }
  };
  const getStoreNameById = (storeId) => {
    const store = stores.find(store => store.store_id === storeId);
    return store ? store.store_name : 'Unknown Store';
  };
  const handleEditClick = (storeId, summary) => {
    setEditingStoreId(storeId);
    setEditedSummary(summary);
  };
  
  const handleInputChange = (e, field) => {
    setEditedSummary({
      ...editedSummary,
      [field]: e.target.value
    });
  };
  
  const handleSaveClick = async () => {
    try {
      const response = await fetch(`${API_ENDPOINTS.INVOICE_DATA_GENERATOR}${editingStoreId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        },
        body: JSON.stringify(editedSummary)
      });
  
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to update store summary');
      }
      notifySuccess(responseData.success);
      invoiceGeneratorData();
    } catch (error) {
      notifyError(`Error: ${error.message}`);
    } finally {
      setEditingStoreId(null);
    }
  };
  
  const handleCancelClick = () => {
    setEditingStoreId(null);
  };
  const handleUploadFileClick = async () => {
    setIsLoadingCSV(true);
    let keyword = 'upload_csv';
    const csv_file = document.getElementById('csvFileInput').files[0];
  
    if (!csv_file) {
      notifyWarning("Please select CSV File to Continue.");
      setIsLoadingCSV(false);
      return;
    }
  
    const formData = new FormData();
    formData.append('keyword', keyword);
    formData.append('csv_file', csv_file);
  
    try {
      let response = await fetch(API_ENDPOINTS.INVOICE_DATA_GENERATOR, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`
        },
        body: formData
      });
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to upload file to server');
      }
      notifySuccess(responseData.success);
      invoiceGeneratorData();
      notifyInfo('Update Machine Tip Before Creating Store Summary');
    } catch (error) {
      notifyError(`Error: ${error.message}`);
    } finally {
      setIsLoadingCSV(false);
    }
  };

  const [filters, setFilters] = useState({
    name: '',
    period: ''
  });
  const [searchInvoiceId, setSearchInvoiceId] = useState('');
  
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };
  
  const handleSearchChange = (e) => {
    setSearchInvoiceId(e.target.value);
  };
  
  const filteredStoreSummary = storeSummary.filter((summary) => {
    return (
      (filters.name === '' || getStoreNameById(summary.store_id).toLowerCase().includes(filters.name.toLowerCase())) &&
      (filters.period === '' || summary.billing_period.toLowerCase().includes(filters.period.toLowerCase())) &&
      (searchInvoiceId === '' || summary.invoice_id.toString().includes(searchInvoiceId))
    );
  });
  return (
    <>
    <Notification />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-5">
            <div className="card mb-4">
              <div className="card-header pb-0 p-3 mb-4">
                <div className="row">
                  <div className="col-4 d-flex align-items-center">
                    <h6 className="mb-3">Pull Data from Dashboard</h6>
                  </div>
                  <div className="col-4 text-end">
                  {isLoading ? (
                    <div className="btn bg-gradient-primary mb-0 disabled" onClick={handlePullData}><i className="material-icons text-sm">add</i>&nbsp;&nbsp;Pull Data</div>
                  ) : (
                    <div className="btn bg-gradient-primary mb-0" onClick={handlePullData}><i className="material-icons text-sm">add</i>&nbsp;&nbsp;Pull Data</div>
                  )}
                  </div>
                  <div className="col-4 text-end">
                      <div className="form-check form-switch d-flex justify-content-end align-items-center">
                        <label className="form-check-label me-5 mb-0" htmlFor="dateRestrictions">
                          Enable Date Restrictions
                        </label>
                        <input 
                          className="form-check-input me-1 mb-0" 
                          type="checkbox" 
                          id="dateRestrictions"
                          checked={dateRestrictionsEnabled}
                          onChange={(e) => setDateRestrictionsEnabled(e.target.checked)}
                        />
                      </div>
                  </div>
                </div>
                <hr className="horizontal dark my-2" />
              </div>
              <div className="card-body p-3">
                  {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <ClipLoader color={"#123abc"} loading={isLoading} size={80} />
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card card-body border card-plain border-radius-md d-flex align-items-center flex-row">
                          <label htmlFor="startDate1" className="form-label" style={{ fontWeight: 'bold' }}>Start Date</label>
                          <input 
                            type="date" 
                            className="form-control" 
                            id="startDate1" 
                            name="startDate1" 
                            style={{ fontSize: '1.25rem' }} 
                            min={dateRestrictionsEnabled ? new Date(new Date(maxDate).setDate(new Date(maxDate).getDate() + 1)).toISOString().split('T')[0] : null}
                            max={dateRestrictionsEnabled ? new Date().toISOString().split('T')[0] : null}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card card-body border card-plain border-radius-md d-flex align-items-center flex-row">
                          <label htmlFor="endDate1" className="form-label" style={{ fontWeight: 'bold' }}>End Date</label>
                          <input 
                            type="date" 
                            className="form-control" 
                            id="endDate1" 
                            name="endDate1" 
                            style={{ fontSize: '1.25rem' }} 
                            min={dateRestrictionsEnabled ? new Date(new Date(maxDate).setDate(new Date(maxDate).getDate() + 1)).toISOString().split('T')[0] : null}
                            max={dateRestrictionsEnabled ? new Date().toISOString().split('T')[0] : null}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card mb-4">
            <div className="card-header pb-0 p-3 mb-3">
                <div className="row">
                  <div className="col-6 d-flex align-items-center">
                    <h6 className="mb-2">Upload CSV File</h6>
                  </div>
                  <hr className="horizontal dark my-2" />
                </div>
              </div>
              <div className="card-body p-3">
              {isLoadingCSV ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <ClipLoader color={"#123abc"} loading={isLoadingCSV} size={80} />
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-body border card-plain border-radius-md d-flex align-items-center flex-row">
                        <input type="file" className="form-control mt-4 mb-3" id="csvFileInput" accept=".csv" onChange={handleUploadFileClick} />
                        </div>
                      </div>
                      
                    </div>
                  )}
                </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card mb-4">
            <div className="card-header pb-0 p-3 mb-3">
                <div className="row">
                  <div className="col-6 d-flex align-items-center">
                    <h6 className="mb-3">Process Data</h6>
                  </div>
                  <div className="col-6 text-end">
                    <div className="mb-0">Delivery Count : {orderToProcess}</div>
                  </div>
                  <hr className="horizontal dark my-2" />
                </div>
              </div>
              <div className="card-body p-3">
                    <div className="row">
                      <div className="col-md-6 text-center">
                        <div className="card card-body border card-plain border-radius-md ">
                          <h6 className='text-center'>Machine Tip</h6>
                          <button className="btn btn-xsm bg-gradient-primary w-100 mb-0 toast-btn" type="button" onClick={handleOpenModal}>Open Form</button>
                            {isModalOpen && (
                              <UpdateMachineModal
                                deliveryData={deliveryData}
                                imageTaskDetails={imageTaskDetails}
                                onClose={handleCloseModal}
                                onSubmit={handleModelSubmit}
                              />
                            )}
                          </div>
                      </div>
                      <div className="col-md-6 text-end">
                        <div className="card card-body border card-plain border-radius-md ">
                        <h6 className='text-center'>Store Summary</h6>
                        <button className="btn btn-xsm bg-gradient-primary w-100 mb-0 toast-btn" type="button" onClick={handleProcessStoreSummary}>Submit Data</button>
                          </div>
                      </div>
                      
                    </div>
                </div>
            </div>
          </div>
        
        
      </div>
      
      <div className="row">
        <div className="col-md-12 mt-1">
          <div className="card">
            
            <div className="card-header pb-0 px-3">
            <div className="row mb-3">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control text-lg p-1"
                  placeholder="Filter by Name"
                  name="name"
                  value={filters.name}
                  onChange={handleFilterChange}
                  style={{border: '1px solid #ccc', borderRadius: '5px'}}
                />
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control text-lg p-1"
                  placeholder="Filter by Period"
                  name="period"
                  value={filters.period}
                  onChange={handleFilterChange}
                  style={{border: '1px solid #ccc', borderRadius: '5px'}}
                />
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control text-lg p-1"
                  placeholder="Search by Invoice ID"
                  value={searchInvoiceId}
                  onChange={handleSearchChange}
                  style={{border: '1px solid #ccc', borderRadius: '5px'}}
                />
              </div>
            </div>
              <h6 className="mb-0">Store Summary</h6>
            </div>
            <div className="card-body pt-4 p-3">
            <div className="table-responsive height_table_max">
            <table className="table align-items-center mb-0">
                <thead>
                    <tr>
                    <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>
                      Name
                    </th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Count</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Tips</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>KM</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Period</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Invoice ID</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '100px'}}>Bonuses</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Misc</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Per Order $</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredStoreSummary.map((summary) => (
                      <tr key={summary.store_summary_id}>
                        <td>
                          <div className="d-flex px-2 py-1">
                            <div className="d-flex flex-column justify-content-center">
                              <h6 className={`mb-0 text-sm`} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                {summary.invoice_id === 0 && <i className="fas fa-tag text-warning mx-1"></i>}
                                {getStoreNameById(summary.store_id)}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className="text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          {editingStoreId === summary.store_summary_id ? (
                            <>
                              <label>Count</label>
                              <input
                                type="number"
                                className="edit-input"
                                value={editedSummary.count}
                                onChange={(e) => handleInputChange(e, 'count')}
                              />
                            </>
                          ) : (
                            <span className="text-xs font-weight-bold">{summary.count}</span>
                          )}
                        </td>
                        <td className="align-middle text-center text-sm" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          {editingStoreId === summary.store_summary_id ? (
                            <>
                              <label>Online Tip</label>
                              <input
                                type="number"
                                className="edit-input"
                                value={editedSummary.online_tip}
                                onChange={(e) => handleInputChange(e, 'online_tip')}
                              />
                              <label>Machine Tip</label>
                              <input
                                type="number"
                                className="edit-input"
                                value={editedSummary.machine_tip}
                                onChange={(e) => handleInputChange(e, 'machine_tip')}
                              />
                            </>
                          ) : (
                            <span className="text-xs font-weight-bold">Online: ${summary.online_tip.toFixed(2)} Machine: ${summary.machine_tip.toFixed(2)}</span>
                          )}
                        </td>
                        <td className="align-middle text-center text-sm" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          {editingStoreId === summary.store_summary_id ? (
                            <>
                              <label>Total Kilometers</label>
                              <input
                                type="number"
                                className="edit-input"
                                value={editedSummary.total_kilometers}
                                onChange={(e) => handleInputChange(e, 'total_kilometers')}
                              />
                              <label>Extra Kilometers</label>
                              <input
                                type="number"
                                className="edit-input"
                                value={editedSummary.extra_km}
                                onChange={(e) => handleInputChange(e, 'extra_km')}
                              />
                            </>
                          ) : (
                            <span className="text-xs font-weight-bold">Total: {summary.total_kilometers} Extra: {summary.extra_km}</span>
                          )}
                        </td>
                        <td className="align-middle text-center text-sm" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          <span className="text-xs font-weight-bold">{summary.billing_period}</span>
                        </td>
                        <td className="align-middle text-center text-sm" style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '100px' }}>
                          <span className="text-xs font-weight-bold">{summary.invoice_id}</span>
                        </td>
                        <td className="align-middle text-center text-sm" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          {editingStoreId === summary.store_summary_id ? (
                            <>
                              <label>AWF</label>
                              <input
                                type="number"
                                className="edit-input"
                                value={editedSummary.additionalcharge_awf}
                                onChange={(e) => handleInputChange(e, 'additionalcharge_awf')}
                              />
                              <label>COB</label>
                              <input
                                type="number"
                                className="edit-input"
                                value={editedSummary.additionalcharge_cob}
                                onChange={(e) => handleInputChange(e, 'additionalcharge_cob')}
                              />
                              <label>FRF</label>
                              <input
                                type="number"
                                className="edit-input"
                                value={editedSummary.additionalcharge_frf}
                                onChange={(e) => handleInputChange(e, 'additionalcharge_frf')}
                              />
                              <label>OCB</label>
                              <input
                                type="number"
                                className="edit-input"
                                value={editedSummary.additionalcharge_ocb}
                                onChange={(e) => handleInputChange(e, 'additionalcharge_ocb')}
                              />
                              <label>WTB</label>
                              <input
                                type="number"
                                className="edit-input"
                                value={editedSummary.additionalcharge_wtb}
                                onChange={(e) => handleInputChange(e, 'additionalcharge_wtb')}
                              />
                            </>
                          ) : (
                            <span className="text-xs font-weight-bold">
                              <span className='mb-0 text-sm'>
                                Total: ${(summary.additionalcharge_awf + summary.additionalcharge_cob + summary.additionalcharge_frf + summary.additionalcharge_ocb + summary.additionalcharge_wtb).toFixed(2) + " "}
                              </span>
                              {summary.additionalcharge_awf > 0.00 && `AWF: $${summary.additionalcharge_awf.toFixed(2)}, `}
                              {summary.additionalcharge_cob > 0.00 && `COB: $${summary.additionalcharge_cob.toFixed(2)}, `}
                              {summary.additionalcharge_frf > 0.00 && `FRF: $${summary.additionalcharge_frf.toFixed(2)}, `}
                              {summary.additionalcharge_ocb > 0.00 && `OCB: $${summary.additionalcharge_ocb.toFixed(2)}, `}
                              {summary.additionalcharge_wtb > 0.00 && `WTB: $${summary.additionalcharge_wtb.toFixed(2)}`}
                            </span>
                          )}
                        </td>
                        <td className="align-middle text-center text-sm" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          {editingStoreId === summary.store_summary_id ? (
                            <>
                              <label>Misc Charge</label>
                              <input
                                type="number"
                                className="edit-input"
                                value={editedSummary.misc_charge}
                                onChange={(e) => handleInputChange(e, 'misc_charge')}
                              />
                              <label>Misc Description</label>
                              <input
                                type="text"
                                className="edit-input"
                                value={editedSummary.misc_descption}
                                onChange={(e) => handleInputChange(e, 'misc_descption')}
                              />
                            </>
                          ) : (
                            summary.misc_charge > 0 ? (
                              <span className="text-xs font-weight-bold">${summary.misc_charge.toFixed(2)} - {summary.misc_descption}</span>
                            ) : (
                              <span className="text-xs font-weight-bold">0</span>
                            )
                          )}
                        </td>
                        <td className="align-middle text-center text-sm" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          {editingStoreId === summary.store_summary_id ? (
                            <>
                              <label>Per Order Fee</label>
                              <input
                                type="number"
                                className="edit-input"
                                value={editedSummary.per_order_fee}
                                onChange={(e) => handleInputChange(e, 'per_order_fee')}
                              />
                            </>
                          ) : (
                            <span className="text-xs font-weight-bold">${summary.per_order_fee.toFixed(2)}</span>
                          )}
                        </td>
                        <td className="align-middle text-center text-sm">
                          {editingStoreId === summary.store_summary_id ? (
                            <>
                              <button className="btn btn-sm bg-gradient-primary" onClick={handleSaveClick}>Save</button>&nbsp;&nbsp;
                              <button className="btn btn-sm bg-gradient-danger" onClick={handleCancelClick}>Cancel</button>
                            </>
                          ) : (
                            <button className="btn btn-edit text-primary" onClick={() => handleEditClick(summary.store_summary_id, summary)}>
                              <i className="material-icons">edit</i>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            </div>
            </div>
          </div>
        </div>
        
      </div>
    </div> 
    </>
  )
}

export default InvoiceDataGenerator
