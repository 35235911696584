import React from 'react';
import Dashboard from '../components/Dashboard';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => {
    return (
        <>
        <SideBar />
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <Header title="Dashboard" />
            
            <Dashboard />
            
        </main>
        <Footer />
        </>
        
    );
    };

export default Home;