// settings.js
let API_URL = '';

if (process.env.NODE_ENV === 'development') {
  API_URL = 'http://localhost:8000';
} else if (process.env.NODE_ENV === 'production') {
  API_URL = 'https://billing.iorders.ca';
}

export const API_ENDPOINTS = {
  TOKEN:`${API_URL}/api/token/`,
  TOKEN_REFRESH: `${API_URL}/api/token/refresh/`,
  DASHBOARD: `${API_URL}/api/dashboard/`,
  HEADER: `${API_URL}/api/header/`,
  INVOICE_DATA_GENERATOR: `${API_URL}/api/process_data/`,
  INVOICE: `${API_URL}/api/invoice/`,
  INVOICE_DATA: `${API_URL}/api/invoice_data/`,
  STORE: `${API_URL}/api/store/`,
  AGENT: `${API_URL}/api/agent/`,
  AGENT_INVOICE: `${API_URL}/api/agent_invoice/`,
};
