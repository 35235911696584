import React from 'react'
import AgentInvoice from '../components/AgentInvoice';
import SideBar from '../components/SideBar';
import Header from '../components/Header';

const AgentInvoicePage = () => {
    return (
        <>
            <SideBar />
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <Header title="Agents" />
                <AgentInvoice />
            </main>
        </>
      )
    }

export default AgentInvoicePage