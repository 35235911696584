import React from 'react'
import Agents from '../components/Agents';
import SideBar from '../components/SideBar';
import Header from '../components/Header';

const AgentPage = () => {
  return (
    <>
        <SideBar />
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <Header title="Agents" />
            <Agents />
        </main>
    </>
  )
}

export default AgentPage