import React, { useState, useEffect } from 'react'
import { API_ENDPOINTS } from '../settings.js';
import Notification, { notifyInfo, notifySuccess, notifyWarning, notifyError } from '../popups/Notification';
import ClipLoader from 'react-spinners/ClipLoader';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const AgentInvoice = () => {
  let [authTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
  const [summaryCount, setSummaryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [verifiedAgentSummaryCount, setVerifiedAgentSummaryCount] = useState(0);
  const [distinctAgentSummaryDates, setDistinctAgentSummaryDates] = useState([]);
  const [agentSummarySelectedDate, setAgentSummarySelectedDate] = useState('');
  const [agents, setAgents] = useState([]);
  const [agentSummary,setAgentSummary] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    report_date: ''
  });
  const [searchAgentId, setSearchAgentId] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const filteredAgentSummary = agentSummary.filter((summary) => {
    return (
      (filters.name === '' || summary.agent_name.toLowerCase().includes(filters.name.toLowerCase())) &&
      (filters.report_date === '' || summary.report_date.includes(filters.report_date)) &&
      (searchAgentId === '' || summary.agent_id.toString().includes(searchAgentId)) &&
      (searchStatus === '' || summary.email_status.toLowerCase().includes(searchStatus.toLowerCase()))
    );
  });
  const [expandedSummaryId, setExpandedSummaryId] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [editableSummary, setEditableSummary] = useState({});
  const [editingStatusId, setEditingStatusId] = useState(null);
  const statusOptions = ['CREATED', 'VERIFIED', 'SEND'];

  const InvoiceData = async () => {
    try {
      let response = await fetch(API_ENDPOINTS.AGENT_INVOICE, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        }
      });
      
      let data = await response.json();
      console.log(data)
      setAgents(data.agents);
      setAgentSummary(data.agent_summary);
      setSummaryCount(data.summary_count);
      setVerifiedAgentSummaryCount(data.verified_agent_summary_count);
      setDistinctAgentSummaryDates(data.distinct_agent_summary_dates);
      if (data.distinct_agent_summary_dates.length > 0) {
        setAgentSummarySelectedDate(data.distinct_agent_summary_dates[0]);
      }
    } catch (error) {
      notifyError("Failed to fetch data.");
    } 
  }
  const GenerateInvoice = async () => {
    let keyword = "Create";
    if (summaryCount === 0) {
      notifyInfo("No data to create Agent Invoice.");
      return;
    } else {
      setIsLoading(true);
    }
  
    try {
      let response = await fetch(API_ENDPOINTS.AGENT_INVOICE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        },
        body: JSON.stringify({ keyword })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        notifyError("Failed to generate Agent Invoice: " + errorData.error);
      } else {
        notifySuccess("Agent Invoice generated successfully.");
        InvoiceData();
      }
    } catch (error) {
      notifyError("Failed to generate Agent Invoice: " + error.message);
    } finally {
      setIsLoading(false);
    }
  }
  const SendVerifiedEmails = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to send the verified emails?</p>
            <div className='custom-ui-buttons'>
              <button
                onClick={async () => {
                  let keyword = "Send";
                  let selected_report_date = agentSummarySelectedDate;
                  try {
                    let response = await fetch(API_ENDPOINTS.AGENT_INVOICE, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`
                      },
                      body: JSON.stringify({ keyword, selected_report_date })
                    });
                    if (!response.ok) {
                      const errorData = await response.json();
                      notifyError("Failed to send verified emails: " + errorData.error);
                    } else {
                      notifySuccess("Verified emails sent successfully.");
                    }
                  } catch (error) {
                    notifyError("Failed to send verified emails: " + error.message);
                  }
                  onClose();
                }}
                className='custom-ui-button custom-ui-button-yes'
              >
                Yes, Send it!
              </button>
              <button 
                onClick={onClose} 
                className='custom-ui-button custom-ui-button-no'
              >
                No
              </button>
            </div>
          </div>
        );
      }
    });
  }
  const GenerateSummaryReport = async () => {
    let keyword = "Report";
    let selected_report_date = agentSummarySelectedDate;
    let response = await fetch(API_ENDPOINTS.AGENT_INVOICE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authTokens.access}`
      },
      body: JSON.stringify({ keyword, selected_report_date })
    });
    if (!response.ok) {
      notifyError("Failed to create Agent Summary report.");
      return;
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Agent Invoice Summary.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);

    notifySuccess("Agent Summary report created successfully.");
  }
  const GenerateTelpayReport = async () => {
    let keyword = "TelpayReport";
    let selected_report_date = agentSummarySelectedDate;
    let response = await fetch(API_ENDPOINTS.AGENT_INVOICE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authTokens.access}`
      },
      body: JSON.stringify({ keyword, selected_report_date })
    });
    if (!response.ok) {
      notifyError("Failed to create Telpay report.");
      return;
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Telpay-PAD-Export.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);

    notifySuccess("Invoice report created successfully.");
  }
  useEffect(() => {
    InvoiceData();
  }, [authTokens]);
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };  
  const handleSearchChangeStatus = (e) => {
    setSearchStatus(e.target.value);
  };
  const handleEdit = (summary) => {
    setEditMode(summary.id);
    setEditableSummary({...summary});
  };

  const handleChange = (field, value) => {
    setEditableSummary(prev => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`${API_ENDPOINTS.AGENT_INVOICE}${editMode}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        },
        body: JSON.stringify(editableSummary)
      });

      if (response.ok) {
        notifySuccess('Agent Summary updated successfully.');
        InvoiceData(); // Refresh the data
      } else {
        const errorData = await response.json();
        notifyError("Failed to update Agent Summary: " + errorData.error);
      }
    } catch (error) {
      notifyError("Failed to update Agent Summary: " + error.message);
    }
    setEditMode(null); // Exit edit mode
};

  const handleCancel = () => {
    setEditMode(null);
    setEditableSummary({});
  };

  const toggleExpandSummary = (summaryId) => {
    setExpandedSummaryId(expandedSummaryId === summaryId ? null : summaryId);
  };

  const handleStatusChange = async (summaryId, newStatus) => {
    try {
      const updatedSummary = { ...agentSummary.find(summary => summary.id === summaryId), email_status: newStatus };
      const response = await fetch(`${API_ENDPOINTS.AGENT_INVOICE}${summaryId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        },
        body: JSON.stringify(updatedSummary)
      });
  
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to update status');
      }
      notifySuccess(responseData.success);
      InvoiceData(); // Refresh data
    } catch (error) {
      notifyError(`Error: ${error.message}`);
    }
  };

  const toggleEditStatus = (summaryId) => {
    setEditingStatusId(editingStatusId === summaryId ? null : summaryId);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'VERIFIED':
        return 'text-info';
      case 'CREATED':
        return 'text-warning';
      case 'SEND':
        return 'text-primary';
      default:
        return 'text-standard';
    }
  };
  const sendEmail = async (invoiceId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to send this email?</p>
            <div className='custom-ui-buttons'>
              <button
                onClick={async () => {
                  try {
                    let invoice_id = invoiceId;
                    let keyword = "send_email_agent_summary";
                    const response = await fetch(API_ENDPOINTS.AGENT_INVOICE, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`
                      },
                      body: JSON.stringify({ keyword, invoice_id })
                    });
  
                    const responseData = await response.json();
                    if (!response.ok) {
                      throw new Error(responseData.error || 'Failed to send email');
                    }
                    notifySuccess("Email sent successfully.");
                    InvoiceData();
                  } catch (error) {
                    notifyError(`Error: ${error.message}`);
                  }
                  onClose();
                }}
                className='custom-ui-button custom-ui-button-yes'
              >
                Yes, Send it!
              </button>
              <button 
                onClick={onClose} 
                className='custom-ui-button custom-ui-button-no'
              >
                No
              </button>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <>
    <Notification />
    <div className="container-fluid py-4">
      <div className="row">
      <div className="col-md-4">
            <div className="card mb-0">
              <div className="card-header pb-2 p-3 mb-2">
                <div className="row">
                  <div className="col-6 d-flex align-items-center">
                    <h6 className="mb-2">Create Invoices</h6>
                  </div>
                  <div className="col-6 text-end">
                    <div className="mb-0">Summary Count :{summaryCount}</div>
                  </div>
                </div>
                <hr className="horizontal dark my-2" />
              </div>
              <div className="card-body p-4 text-center">
              {isLoading ? (
                    <div className="btn bg-gradient-primary mb-0 d-flex justify-content-center align-items-center disabled" onClick={GenerateInvoice}><ClipLoader color={"white"} loading={isLoading} size={30} />&nbsp;&nbsp;Create Invoices</div>
                  ) : (
                    <div className="btn bg-gradient-primary mb-3 d-flex justify-content-center align-items-center" onClick={GenerateInvoice}><i className="material-icons text-sm">add</i>&nbsp;&nbsp;Create Invoices</div>
                  )}
              </div>
            </div>
      </div>
      <div className="col-md-8">
      <div className="card mb-4">
        <div className="card-header pb-0 p-3 mb-3">
          <div className="row">
            <div className="col-6 d-flex align-items-center">
              <h6 className="mb-3">Send Invoices</h6>
            </div>
            <div className="col-6 text-end">
              <div className="mb-0">Total Verified Agent Summary : {verifiedAgentSummaryCount}</div>
            </div>
            <hr className="horizontal dark my-0" />
          </div>
        </div>
        <div className="card-body p-3">
          <div className="row">
            <div className="col-md-3 text-center">
              <div className="card card-body border card-plain border-radius-md ">
                <h6 className='text-center'>Agent Summary Date</h6>
                <select 
                  className="form-control date-dropdown" 
                  value={agentSummarySelectedDate} 
                  onChange={(e) => setAgentSummarySelectedDate(e.target.value)}
                >
                  <option value="" disabled>Select Date</option>
                  {distinctAgentSummaryDates.map((date, index) => (
                    <option key={index} value={date}>{date}</option>
                  ))}
                </select>
                </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="card card-body border card-plain border-radius-md ">
              <h6 className='text-center'>Send Verified Email</h6>
              <button className="btn btn-xsm bg-gradient-primary w-100 mb-0 toast-btn" type="button" onClick={SendVerifiedEmails}>Send</button>
                </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="card card-body border card-plain border-radius-md ">
              <h6 className='text-center'>Agent Summary Report</h6>
              <button className="btn btn-xsm bg-gradient-primary w-100 mb-0 toast-btn" type="button" onClick={GenerateSummaryReport}>Generate</button>
                </div>
            </div>
            <div className="col-md-3 text-center">
              <div className="card card-body border card-plain border-radius-md ">
              <h6 className='text-center'>Telpay Agent Report</h6>
              <button className="btn btn-xsm bg-gradient-primary w-100 mb-0 toast-btn" type="button" onClick={GenerateTelpayReport}>Generate</button>
                </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      </div>
      <div className="row">
      <div className="col-md-12 mt-1">
        <div className="card">
          <div className="card-header pb-0 px-3">
            <div className="row mb-3">
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control text-lg p-1"
                  placeholder="Filter by Name"
                  name="name"
                  value={filters.name}
                  onChange={handleFilterChange} 
                  style={{border: '1px solid #ccc', borderRadius: '5px'}}
                />
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control text-lg p-1"
                  placeholder="Filter by Report Date"
                  name="report_date"
                  value={filters.report_date}
                  onChange={handleFilterChange}
                  style={{border: '1px solid #ccc', borderRadius: '5px'}}
                />
              </div>
              <div className="col-md-4">
                <select
                  className="form-control text-lg p-1"
                  value={searchStatus}
                  onChange={handleSearchChangeStatus}
                  style={{border: '1px solid #ccc', borderRadius: '5px'}}
                >
                  <option value="">All Status</option>
                  <option value="CREATED">CREATED</option>
                  <option value="VERIFIED">VERIFIED</option>
                  <option value="SEND">SEND</option>
                </select>
              </div>
            </div>
            <h6 className="mb-0">Agent Summary</h6>
          </div>
          <div className="card-body pt-4 p-3">
            <div className="table-responsive height_table_max">
              <table className="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width:"10%"}}>Agent Name</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Count</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Delivery Payment</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Report Date</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Kilometers</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Tips and Bonus</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Fuel Allowance</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Total Amount</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Cash From Customer</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Morning Bonus</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Status</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAgentSummary.map((summary) => (
                    <>
                    <tr >
                      <td key={summary.id} onClick={() => toggleExpandSummary(summary.id)} style={{cursor: 'pointer'}}>
                        <div className="d-flex px-2 py-1">
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className={`mb-0 text-sm`} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                              {summary.email_status === 'CREATED' && <i className="fas fa-tag text-warning mx-1"></i>}
                              {summary.agent_name}
                            </h6>
                          </div>
                        </div>
                      </td>
                      
                      <td className="text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        <span className="text-xs font-weight-bold">{summary.count}</span>
                      </td>
                      <td className="text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        <span className="text-xs font-weight-bold">{summary.delivery_payment}</span>
                      </td>
                      <td className="align-middle text-center text-sm" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        <span className="text-xs font-weight-bold">{summary.report_date}</span>
                      </td>
                    
                      <td className="text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        <span className="text-xs font-weight-bold">
                          Total KM: <strong className='text-primary'>{summary.sum_of_km}</strong><br/>
                          Extra KM: <strong className='text-primary'>{summary.sum_of_extra_km}</strong>
                        </span>
                      </td>
                      <td className="text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        <span className="text-xs font-weight-bold">
                            Online: <strong className='text-primary'>{summary.sum_of_online_tip}</strong><br/>
                            Machine: <strong className='text-primary'>{summary.sum_of_machine_tip}</strong><br/>
                            Bonus: <strong className='text-primary'>{summary.sum_of_bonus}</strong>
                        </span>
                      </td>
                      <td className="text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        <span className="text-xs font-weight-bold">{summary.sum_of_fuel_allowance}</span>
                      </td>
                      <td className="text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        <span className="text-xs font-weight-bold">{summary.amount}</span>
                      </td>
                      <td className="text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        <span className="text-xs font-weight-bold">{summary.cash_from_customer}</span>
                      </td>
                      <td className="text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        <span className="text-xs font-weight-bold">{summary.morning_bonus}</span>
                      </td>
                      <td className="align-middle text-center text-sm">
                        {editingStatusId === summary.id ? (
                          <select
                            className={`form-control px-3 ${getStatusClass(summary.email_status)}`}
                            value={summary.email_status}
                            onChange={(e) => handleStatusChange(summary.id, e.target.value)}
                            onBlur={() => toggleEditStatus(null)}
                          >
                            {statusOptions.map((status) => (
                              <option key={status} value={status} className={`text-primary ${getStatusClass(status)}`}>{status} </option>
                            ))}
                          </select>
                        ) : (
                          <span
                            className={`text-xs font-weight-bold ${getStatusClass(summary.email_status)}`}
                            onClick={() => toggleEditStatus(summary.id)}
                            style={{cursor: 'pointer'}}
                          >
                            {summary.email_status}
                          </span>
                        )}
                      </td>
                      <td className="align-middle text-center text-sm">
                          <button 
                            className="btn btn-edit text-primary" 
                            onClick={() => sendEmail(summary.id)}
                            disabled={summary.email_status !== 'VERIFIED'} 
                          >
                            <i className="material-icons" style={{ fontSize: '40px' }}>
                              {summary.email_status === 'SEND' || summary.email_status === 'PAID' ? 'mark_email_read' : 'forward_to_inbox'}
                            </i>
                          </button>
                        </td>
                    </tr>
                    {expandedSummaryId === summary.id && (
                      <tr>
                        <td colSpan="12">
                          <div className="card card-body shadow-xxl m-3">
                            <div className="row gx-4 mb-2">
                              <div className="col-auto">
                                <div className="avatar avatar-xl position-relative">
                                  <i className="material-icons text-primary w-100 border-radius-lg shadow-sm" style={{fontSize: '80px'}}>person</i>
                                </div>
                              </div>
                              <div className="col-auto my-auto">
                                <div className="h-100">
                                  <h5 className="mb-1">{summary.agent_name}</h5>
                                  <h6 className="mb-1">{summary.agent_id}</h6>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                                          <div className="nav-wrapper position-relative end-0">
                                              <ul className="nav nav-pills nav-fill p-1" role="tablist">
                                                  <li className="nav-item">
                                                      {editMode === summary.id ? (
                                                          <div style={{ display: 'flex', gap: '10px' }}>
                                                              <button className="nav-link mb-0 px-0 py-1 bg-gray-400" onClick={handleSave}>
                                                                  <i className="material-icons text-lg position-relative">save</i>
                                                                  <span className="ms-1">Save</span>
                                                              </button>
                                                              <button className="nav-link mb-0 px-0 py-1 bg-gray-400" onClick={handleCancel}>
                                                                  <i className="material-icons text-lg position-relative">close</i>
                                                                  <span className="ms-1">Cancel</span>
                                                              </button>
                                                          </div>
                                                      ) : (
                                                          <button className="nav-link mb-0 px-0 py-1 bg-gray-400" onClick={() => handleEdit(summary)}>
                                                              <i className="material-icons text-lg position-relative">mode_edit</i>
                                                              <span className="ms-1">Edit</span>
                                                          </button>
                                                      )}
                                                  </li>
                                              </ul>
                                          </div>
                                      </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-xl-4">
                              <h6 className="mb-0">Agent Summary Information</h6>
                              <ul className="list-group p-1">
                                <li className="list-group-item border-0 p-0 text-sm">
                                  <strong className="text-dark">Count:</strong> &nbsp;
                                  {editMode === summary.id ? (
                                    <input
                                      type="text"
                                      value={editableSummary.count}
                                      onChange={(e) => handleChange('count', e.target.value)}
                                      className='edit-input'
                                      style={{ width: '200px' }}
                                    />
                                  ) : (
                                    summary.count
                                  )}
                                </li>
                                <li className="list-group-item border-0 p-0 text-sm">
                                  <strong className="text-dark">Amount:</strong> &nbsp;
                                  {editMode === summary.id ? (
                                    <input
                                      type="text"
                                      value={editableSummary.amount}
                                      onChange={(e) => handleChange('amount', e.target.value)}
                                      className='edit-input'
                                      style={{ width: '200px' }}
                                    />
                                  ) : (
                                    summary.amount
                                  )}
                                </li>
                                <li className="list-group-item border-0 p-0 text-sm">
                                  <strong className="text-dark">Delivery Payment:</strong> &nbsp;
                                  {editMode === summary.id ? (
                                    <input
                                      type="text"
                                      value={editableSummary.delivery_payment}
                                      onChange={(e) => handleChange('delivery_payment', e.target.value)}
                                      className='edit-input'
                                      style={{ width: '200px' }}
                                    />
                                  ) : (
                                    summary.delivery_payment
                                  )}
                                </li>
                                <li className="list-group-item border-0 p-0 text-sm">
                                  <strong className="text-dark">Sum of Machine Tip:</strong> &nbsp;
                                  {editMode === summary.id ? (
                                    <input
                                      type="text"
                                      value={editableSummary.sum_of_machine_tip}
                                      onChange={(e) => handleChange('sum_of_machine_tip', e.target.value)}
                                      className='edit-input'
                                      style={{ width: '200px' }}
                                    />
                                  ) : (
                                    summary.sum_of_machine_tip
                                  )}
                                </li>
                                <li className="list-group-item border-0 p-0 text-sm">
                                  <strong className="text-dark">Sum of Online Tip:</strong> &nbsp;
                                  {editMode === summary.id ? (
                                    <input
                                      type="text"
                                      value={editableSummary.sum_of_online_tip}
                                      onChange={(e) => handleChange('sum_of_online_tip', e.target.value)}
                                      className='edit-input'
                                      style={{ width: '200px' }}
                                    />
                                  ) : (
                                    summary.sum_of_online_tip
                                  )}
                                </li>
                              </ul>
                              </div>
                              <div className="col-12 col-xl-4">
                                <div className="card card-plain h-100">
                                  <div className="card-header pb-0 p-3">
                                    <h6 className="mb-2"></h6>
                                  </div>
                                  <div className="card-body p-1">
                                    <ul className="list-group p-1">
                                      <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Bonus:</strong> &nbsp;
                                        {editMode === summary.id ? (
                                          <input
                                            type="text"
                                            value={editableSummary.sum_of_bonus}
                                            onChange={(e) => handleChange('sum_of_bonus', e.target.value)}
                                            className='edit-input'
                                            style={{ width: '200px' }}
                                          />
                                        ) : (
                                          summary.sum_of_bonus
                                        )}
                                      </li>
                                      <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Cash From Customer:</strong> &nbsp;
                                        {editMode === summary.id ? (
                                          <input
                                            type="text"
                                            value={editableSummary.cash_from_customer}
                                            onChange={(e) => handleChange('cash_from_customer', e.target.value)}
                                            className='edit-input'
                                            style={{ width: '200px' }}
                                          />
                                        ) : (
                                          summary.cash_from_customer
                                        )}
                                      </li>
                                      <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Morning Bonus:</strong> &nbsp;
                                        {editMode === summary.id ? (
                                          <input
                                            type="text"
                                            value={editableSummary.morning_bonus}
                                            onChange={(e) => handleChange('morning_bonus', e.target.value)}
                                            className='edit-input'
                                            style={{ width: '200px' }}
                                          />
                                        ) : (
                                          summary.morning_bonus
                                        )}
                                      </li>
                                      <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Sum of KM:</strong> &nbsp;
                                        {editMode === summary.id ? (
                                          <input
                                            type="text"
                                            value={editableSummary.sum_of_km}
                                            onChange={(e) => handleChange('sum_of_km', e.target.value)}
                                            className='edit-input'
                                            style={{ width: '200px' }}
                                          />
                                        ) : (
                                          summary.sum_of_km
                                        )}
                                      </li>
                                      <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Sum of Extra KM:</strong> &nbsp;
                                        {editMode === summary.id ? (
                                          <input
                                            type="text"
                                            value={editableSummary.sum_of_extra_km}
                                            onChange={(e) => handleChange('sum_of_extra_km', e.target.value)}
                                            className='edit-input'
                                            style={{ width: '200px' }}
                                          />
                                        ) : (
                                          summary.sum_of_extra_km
                                        )}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-xl-4">
                                <div className="card card-plain h-100">
                                  <div className="card-header pb-0 p-3">
                                    <h6 className="mb-2"></h6>
                                  </div>
                                  <div className="card-body p-3">
                                    <ul className="list-group ">
                                      <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Cash on Hold:</strong> &nbsp;
                                        {editMode === summary.id ? (
                                          <input
                                            type="text"
                                            value={editableSummary.cash_on_hold}
                                            onChange={(e) => handleChange('cash_on_hold', e.target.value)}
                                            className='edit-input'
                                            style={{ width: '200px' }}
                                          />
                                        ) : (
                                          summary.cash_on_hold
                                        )}
                                      </li>
                                      <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Average Total Time:</strong> &nbsp;
                                        {editMode === summary.id ? (
                                          <input
                                            type="text"
                                            value={editableSummary.avg_total_time}
                                            onChange={(e) => handleChange('avg_total_time', e.target.value)}
                                            className='edit-input'
                                            style={{ width: '200px' }}
                                          />
                                        ) : (
                                          summary.avg_total_time
                                        )}
                                      </li>
                                      <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Sum of Fuel Allowance:</strong> &nbsp;
                                        {editMode === summary.id ? (
                                          <input
                                            type="text"
                                            value={editableSummary.sum_of_fuel_allowance}
                                            onChange={(e) => handleChange('sum_of_fuel_allowance', e.target.value)}
                                            className='edit-input'
                                            style={{ width: '200px' }}
                                          />
                                        ) : (
                                          summary.sum_of_fuel_allowance
                                        )}
                                      </li>
                                      
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
</div>
    </div>
    </>
  )
}

export default AgentInvoice
