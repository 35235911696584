import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import './Dashboard.css';
import AuthContext from '../context/AuthContext';

const SideBar = () => {
    let { logoutUser } = useContext(AuthContext);
    return (
        <>
            <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark" id="sidenav-main">
                <div className="sidenav-header">
                    <i className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
                    <a className="navbar-brand m-0" href="/home">
                        <span className="ms-1 font-weight-bold text-white">Billing System</span>
                    </a>
                </div>
                <hr className="horizontal light mt-0 mb-2" />
                <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) => `nav-link text-white ${isActive ? 'active bg-gradient-primary' : ''}`}
                                to="/home"
                            >
                                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">dashboard</i>
                                </div>
                                <span className="nav-link-text ms-1">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) => `nav-link text-white ${isActive ? 'active bg-gradient-primary' : ''}`}
                                to="/invoice-data"
                            >
                                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">table_view</i>
                                </div>
                                <span className="nav-link-text ms-1">Process Delivery Data</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) => `nav-link text-white ${isActive ? 'active bg-gradient-primary' : ''}`}
                                to="/invoices"
                            >
                                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">receipt_long</i>
                                </div>
                                <span className="nav-link-text ms-1">Store Invoices</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) => `nav-link text-white ${isActive ? 'active bg-gradient-primary' : ''}`}
                                to="/agent-invoices"
                            >
                                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">receipt</i>
                                </div>
                                <span className="nav-link-text ms-1">Agent Invoices</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) => `nav-link text-white ${isActive ? 'active bg-gradient-primary' : ''}`}
                                to="/stores"
                            >
                                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">store</i>
                                </div>
                                <span className="nav-link-text ms-1">Stores</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                className={({ isActive }) => `nav-link text-white ${isActive ? 'active bg-gradient-primary' : ''}`}
                                to="/agents"
                            >
                                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">group</i>
                                </div>
                                <span className="nav-link-text ms-1">Agents</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="sidenav-footer position-absolute w-100 bottom-0">
                    <div className="mx-3">
                        <button className="btn bg-gradient-primary w-100" type="button" onClick={logoutUser}>Log Out</button>
                    </div>
                    <div className="mx-3">
                        <p style={{textAlign: 'center', fontSize: '12px'}}>Version 3.0.0</p>
                    </div>
                </div>
            </aside>
        </>
    );
}

export default SideBar;