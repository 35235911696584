import React, { useState, useEffect } from 'react';
import { API_ENDPOINTS } from '../settings.js';
import Notification, { notifyInfo, notifySuccess, notifyWarning, notifyError } from '../popups/Notification';

const Stores = () => {
    const [stores, setStores] = useState([]);
    let [authTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
    const [nameFilter, setNameFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [padFilter, setPadFilter] = useState('');
    const [expandedStoreId, setExpandedStoreId] = useState(null); 
    const [editMode, setEditMode] = useState(null);
    const [editableStore, setEditableStore] = useState({});
    const handleEdit = (store) => {
        setEditMode(store.store_id);
        setEditableStore({
            store_name: store.store_name,
            store_phone: store.store_phone,
            store_email: store.store_email,
            store_address: store.store_address,
            store_province: store.store_province,
            billing_type: store.billing_type,
            store_delivery_rate: store.store_delivery_rate,
            store_delivery_sub_fee: store.store_delivery_sub_fee,
            store_online_sub_fee: store.store_online_sub_fee,
            store_extra_km_charge: store.store_extra_km_charge,
            default_tip: store.default_tip,
            extra_km_buffer: store.extra_km_buffer,
            store_ocb_charge: store.store_ocb_charge,
            telPay_Name: store.telPay_Name,
            website_hosting_fee: store.website_hosting_fee,
            fuel_surcharge: store.fuel_surcharge,
            per_order_fee: store.per_order_fee,
            tablet_rent_fee: store.tablet_rent_fee,
            marketing_services_fee: store.marketing_services_fee,
            address_correction_fee: store.address_correction_fee,
            misc_charge: store.misc_charge,
            misc_description: store.misc_description,
            reminder_twilio_phone_number: store.reminder_twilio_phone_number,
            free_subsciption_period: store.free_subsciption_period,
            first_reminder_call_accept_fee: store.first_reminder_call_accept_fee,
            second_reminder_call_accept_fee: store.second_reminder_call_accept_fee,
            reminder_call_dispatch_accept_fee: store.reminder_call_dispatch_accept_fee,
            first_reminder_call_request_fee: store.first_reminder_call_request_fee,
            second_reminder_call_request_fee: store.second_reminder_call_request_fee,
            reminder_call_dispatch_request_fee: store.reminder_call_dispatch_request_fee,
        });
      };
    const handleChange = (field, value) => {
        setEditableStore(prev => ({ ...prev, [field]: value }));
    };
    const handleSave = async () => {
        // Use editableStore to update the store details on the server
        try {
          const response = await fetch(`${API_ENDPOINTS.STORE}${editMode}/`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authTokens.access}`
            },
            body: JSON.stringify(editableStore)
          });
      
          if (response.ok) {
            notifySuccess('Store updated successfully.');
            StoreData(); // Refresh the store data
          } else {
            throw new Error('Failed to update store');
          }
        } catch (error) {
          notifyError(error.message);
        }
        setEditMode(null); // Exit edit mode
      };
    const handleCancel = () => {
        setEditMode(null);
        setEditableStore({});
    };
    const filteredStores = stores.filter(store => 
        store.store_name.toLowerCase().includes(nameFilter.toLowerCase()) &&
        (statusFilter === '' || store.store_billing_status === statusFilter) &&
        (padFilter === '' || store.store_pad_status.toString() === padFilter)
    );
    const toggleExpandStore = (storeId) => {
        setExpandedStoreId(expandedStoreId === storeId ? null : storeId);
    };
    
    const StoreData = async () => {
        try {
          let response = await fetch(API_ENDPOINTS.STORE, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authTokens.access}`
            }
          });
          
          let data = await response.json();
          setStores(data.stores);
          console.log(data.stores)
        } catch (error) {
          notifyError("Failed to fetch data.");
        } 
      }
    useEffect(() => {
    StoreData();
    }, [authTokens]);
    const handleUpdateStore = async (store, attribute, newValue) => {
        try {
          const updatedStore = {
            ...store, // Spread the existing store attributes
            [attribute]: newValue // Update the specific attribute
          };
      
          const response = await fetch(`${API_ENDPOINTS.STORE}${store.store_id}/`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authTokens.access}`
            },
            body: JSON.stringify(updatedStore)
          });
          console.log(response)
      
          if (response.ok) {
            notifySuccess('Store updated successfully.');
            StoreData(); 
          } else {
            throw new Error('Failed to update store');
          }
        } catch (error) {
          notifyError(error.message);
        }
      };
  return (
    <>
    <Notification />
    <div className="container-fluid px-2 px-md-4">
      <div className="page-header min-height-10 border-radius-xl mt-4">
        <h3>All Stores</h3>
      </div>
      <div className="card mb-4">
            <div className="card-header pb-0 px-3">
            <div className="row mb-3">
            <div className="col-md-4">
            <input
                type="text"
                className="form-control text-lg p-1"
                placeholder="Filter by Name"
                value={nameFilter}
                onChange={e => setNameFilter(e.target.value)}
                style={{border: '1px solid #ccc', borderRadius: '5px'}}
            />
            </div>
            <div className="col-md-4">
            <select
                className="form-control text-lg p-1"
                value={statusFilter}
                onChange={e => setStatusFilter(e.target.value)}
                style={{border: '1px solid #ccc', borderRadius: '5px'}}
            >
                <option value="">All Statuses</option>
                <option value="enabled">Enabled</option>
                <option value="disabled">Disabled</option>
            </select>
            </div>
            <div className="col-md-4">
            <select
                className="form-control text-lg p-1"
                value={padFilter}
                onChange={e => setPadFilter(e.target.value)}
                style={{border: '1px solid #ccc', borderRadius: '5px'}}
            >
                <option value="">All PAD Statuses</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
            </select>
            </div>
            </div>
              <h6 className="mb-0">Store Details</h6>
              <p className="m-0 p-0">Click On each store to expand details</p>
            </div>
            <div className="card-body pt-4 p-3">
            <div className="table-responsive height_table_max">
            <table className="table align-items-center mb-0">
            <thead>
            <tr>
                <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '250px'}}>Store Name</th>
                <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Phone</th>
                <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Store Status</th>
                <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">PAD Status</th>
                <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Billing Period</th>
                <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10">Email</th>
            </tr>
            </thead>
            <tbody>
            {filteredStores.map((store) => (
                <>
                <tr>
                <td key={store.store_id} onClick={() => toggleExpandStore(store.store_id)} style={{cursor: 'pointer'}}>
                  <div className="d-flex px-0 py-1">
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className={`mb-0 text-sm`} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        {store.store_name}
                      </h6>
                    </div>
                  </div>
                </td>
                <td className="text-center">{store.store_phone}</td>
                <td className="text-center form-switch p-0">
                <label className={`form-check-label w-50 m-0 p-2 ${store.store_billing_status.toUpperCase() === 'ENABLED' ? 'text-primary' : 'text-warning'}`}>
                    {store.store_billing_status.toUpperCase()}
                </label>
                <input
                    className="form-check-input m-2 p-2"
                    type="checkbox"
                    id={`flexSwitchCheckDefault-${store.store_id}`}
                    style={{ float: 'center', margin: '0' }}
                    checked={store.store_billing_status.toUpperCase() === 'ENABLED'}
                    onChange={() => handleUpdateStore(store, 'store_billing_status', store.store_billing_status !== 'enabled' ? 'enabled' : 'disabled')}
                />
                </td>
                <td className="text-center form-switch p-0">
                <label className={`form-check-label w-50 m-0 p-2 ${store.store_pad_status ? 'text-primary' : 'text-warning'}`}>
                    {store.store_pad_status ? "Active" : "Inactive"}
                </label>
                <input
                    className="form-check-input m-2 p-2"
                    type="checkbox"
                    id={`flexSwitchCheckPad-${store.store_id}`}
                    style={{ float: 'center', margin: '0' }}
                    checked={store.store_pad_status}
                    onChange={() => handleUpdateStore(store, 'store_pad_status', store.store_pad_status ? 'False' : 'True')}
                />
                </td>
                <td className="text-center">{store.billing_type}</td>
                <td className="text-center">{store.store_email}</td>
                </tr>
                {expandedStoreId === store.store_id && (
                    <tr>
                        <td colSpan="12">
                        <div className="card card-body shadow-xxl m-3">
                            <div className="row gx-4 mb-2">
                                <div className="col-auto">
                                    <div className="avatar avatar-xl position-relative">
                                        <i className="material-icons text-primary w-100 border-radius-lg shadow-sm" style={{fontSize: '80px'}}>store</i>
                                    </div>
                                </div>
                                <div className="col-auto my-auto">
                                    <div className="h-100">
                                    {editMode === store.store_id ? (
                                        <input
                                        type="text"
                                        value={editableStore.store_name}
                                        onChange={(e) => handleChange('store_name', e.target.value)}
                                        className='edit-input'
                                        />
                                    ) : (
                                        <h5 className="mb-1">{store.store_name}</h5>
                                    )}
                                    
                                    {editMode === store.store_id ? (
                                        <p className="mb-0 font-weight-normal text-sm">
                                            <input
                                                type="text"
                                                value={editableStore.store_phone}
                                                onChange={(e) => handleChange('store_phone', e.target.value)}
                                                className='edit-input'
                                            />
                                            <input
                                                type="text"
                                                value={editableStore.store_email}
                                                onChange={(e) => handleChange('store_email', e.target.value)}
                                                className='edit-input'
                                            />
                                        </p>
                                    ) : (
                                        <p className="mb-0 font-weight-normal text-sm">
                                            {store.store_phone}, {store.store_email}
                                        </p>
                                    )}
                                    {editMode === store.store_id ? (
                                        <>
                                        <input
                                            type="text"
                                            value={editableStore.store_address}
                                            onChange={(e) => handleChange('store_address', e.target.value)}
                                            className='edit-input'
                                        />
                                        <input
                                            type="text"
                                            value={editableStore.store_province}
                                            onChange={(e) => handleChange('store_province', e.target.value)}
                                            className='edit-input'
                                        />
                                        </>
                                    ) : (
                                        <p className="mb-0 font-weight-normal text-sm">
                                            {store.store_address}
                                        </p>
                                    )}
                                    <p className="mb-0 font-weight-normal text-sm">
                                        Creation Date: {new Date(store.created_datetime).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}
                                    </p>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                                    <div class="nav-wrapper position-relative end-0">
                                    <ul class="nav nav-pills nav-fill p-1 " role="tablist">
                                        <li class="nav-item">
                                        {editMode === store.store_id ? (
                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                <button className="nav-link mb-0 px-0 py-1 bg-gray-400" onClick={handleSave}>
                                                    <i className="material-icons text-lg position-relative">save</i>
                                                    <span className="ms-1">Save</span>
                                                </button>
                                                <button className="nav-link mb-0 px-0 py-1 bg-gray-400" onClick={handleCancel}>
                                                    <i className="material-icons text-lg position-relative">close</i>
                                                    <span className="ms-1">Cancel</span>
                                                </button>
                                            </div>
                                        ) : (
                                            <button className="nav-link mb-0 px-0 py-1 bg-gray-400" onClick={() => handleEdit(store)}>
                                            <i className="material-icons text-lg position-relative">mode_edit</i>
                                            <span className="ms-1">Edit</span>
                                            </button>
                                        )}
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-xl-3">
                                <div className="card card-plain h-100">
                                    <div className="card-header pb-0 p-3">
                                    <h6 className="mb-0">All Status</h6>
                                    </div>
                                    <div className="card-body p-2">
                                    <hr className="horizontal dark my-1" />
                                    <ul className="list-group">
                                        <li className="list-group-item border-0 px-0">
                                        <div className="form-check form-switch ps-0">
                                            <input 
                                                className="form-check-input ms-auto" 
                                                type="checkbox" 
                                                id="flexSwitchCheckDefault" 
                                                checked={store.address_correction_status} 
                                                onChange={() => handleUpdateStore(store, 'address_correction_status', store.address_correction_status ? 'False' : 'True')}
                                            />
                                            <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0">Billing for Address Correction Reminder call </label>
                                        </div>
                                        </li>
                                        <li className="list-group-item border-0 px-0">
                                        <div className="form-check form-switch ps-0">
                                            <input 
                                                className="form-check-input ms-auto" 
                                                type="checkbox" 
                                                id="flexSwitchCheckDefault1" 
                                                checked={store.free_subsciption} 
                                                onChange={() => handleUpdateStore(store, 'free_subsciption', store.free_subsciption ? 'False' : 'True')}
                                            />
                                            <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0">Free Subscription Status</label>
                                        </div>
                                        </li>
                                        <li className="list-group-item border-0 px-0">
                                        <div className="form-check form-switch ps-0">
                                            <input 
                                                className="form-check-input ms-auto" 
                                                type="checkbox" 
                                                id="flexSwitchCheckDefault2" 
                                                checked={store.store_PerOrderFee} 
                                                onChange={() => handleUpdateStore(store, 'store_PerOrderFee', store.store_PerOrderFee ? 'False' : 'True')}
                                            />
                                            <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0">Billing for Per order Fee </label>
                                        </div>
                                        </li>
                                        <li className="list-group-item border-0 px-0">
                                        <div className="form-check form-switch ps-0">
                                            <input 
                                                className="form-check-input ms-auto" 
                                                type="checkbox" 
                                                id="flexSwitchCheckDefault2" 
                                                checked={store.reminder_call_status} 
                                                onChange={() => handleUpdateStore(store, 'reminder_call_status', store.reminder_call_status ? 'False' : 'True')}
                                            />
                                            <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0">Billing for all reminder Call </label>
                                        </div>
                                        </li>
                                        
                                    </ul>
                                    </div>
                                </div>
                                </div>
                                <div className="col-12 col-xl-3">
                                <div className="card card-plain h-100">
                                    <div className="card-header pb-0 p-3">
                                    <div className="row">
                                        <div className="col-md-8 d-flex align-items-center">
                                        <h6 className="mb-0 ">Billing Rates & Details</h6>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="card-body p-1">
                                    <hr className="horizontal dark my-2" />
                                    <ul className="list-group p-1">
                                        <li className="list-group-item border-0 p-0 text-sm">
                                            <strong className="text-dark">Billing Period:</strong> &nbsp;
                                            {editMode === store.store_id ? (
                                                <select
                                                    value={editableStore.billing_type}
                                                    onChange={(e) => handleChange('billing_type', e.target.value)}
                                                    className='edit-input'
                                                    style={{ width: '100px' }}
                                                >
                                                    <option value="weekly">Weekly</option>
                                                    <option value="monthly">Monthly</option>
                                                </select>
                                            ) : (
                                                store.billing_type.toUpperCase()
                                            )}
                                        </li>
                                        <li className="list-group-item border-0 p-0 text-sm">
                                            <strong className="text-dark">Delivery Rate:</strong> &nbsp;
                                            {editMode === store.store_id ? (
                                                <input
                                                    type="text"
                                                    value={editableStore.store_delivery_rate}
                                                    onChange={(e) => handleChange('store_delivery_rate', e.target.value)}
                                                    className='edit-input'
                                                    style={{ width: '100px' }}
                                                />
                                            ) : (
                                                `$ ${store.store_delivery_rate}`
                                            )}
                                        </li>
                                        <li className="list-group-item border-0 p-0 text-sm">
                                            <strong className="text-dark">Delivery Subscription Fee:</strong> &nbsp;
                                            {editMode === store.store_id ? (
                                                <input
                                                    type="text"
                                                    value={editableStore.store_delivery_sub_fee}
                                                    onChange={(e) => handleChange('store_delivery_sub_fee', e.target.value)}
                                                    className='edit-input'
                                                    style={{ width: '100px' }}
                                                />
                                            ) : (
                                                `$ ${store.store_delivery_sub_fee}`
                                            )}
                                        </li>
                                        <li className="list-group-item border-0 p-0 text-sm">
                                            <strong className="text-dark">Online Subscription Fee:</strong> &nbsp;
                                            {editMode === store.store_id ? (
                                                <input
                                                    type="text"
                                                    value={editableStore.store_online_sub_fee}
                                                    onChange={(e) => handleChange('store_online_sub_fee', e.target.value)}
                                                    className='edit-input'
                                                    style={{ width: '100px' }}
                                                />
                                            ) : (
                                                `$ ${store.store_online_sub_fee}`
                                            )}
                                        </li>
                                        <li className="list-group-item border-0 p-0 text-sm">
                                            <strong className="text-dark">Extra KM Charge:</strong> &nbsp;
                                            {editMode === store.store_id ? (
                                                <input
                                                    type="text"
                                                    value={editableStore.store_extra_km_charge}
                                                    onChange={(e) => handleChange('store_extra_km_charge', e.target.value)}
                                                    className='edit-input'
                                                    style={{ width: '100px' }}
                                                />
                                            ) : (
                                                `$ ${store.store_extra_km_charge}`
                                            )}
                                        </li>
                                        <li className="list-group-item border-0 p-0 text-sm">
                                            <strong className="text-dark">Default Tip:</strong> &nbsp;
                                            {editMode === store.store_id ? (
                                                <input
                                                    type="text"
                                                    value={editableStore.default_tip}
                                                    onChange={(e) => handleChange('default_tip', e.target.value)}
                                                    className='edit-input'
                                                    style={{ width: '100px' }}
                                                />
                                            ) : (
                                                `$ ${store.default_tip}`
                                            )}
                                        </li>
                                        <li className="list-group-item border-0 p-0 text-sm">
                                            <strong className="text-dark">Extra KM Buffer:</strong> &nbsp;
                                            {editMode === store.store_id ? (
                                                <input
                                                    type="text"
                                                    value={editableStore.extra_km_buffer}
                                                    onChange={(e) => handleChange('extra_km_buffer', e.target.value)}
                                                    className='edit-input'
                                                    style={{ width: '100px' }}
                                                />
                                            ) : (
                                                store.extra_km_buffer
                                            )}
                                        </li>
                                        <li className="list-group-item border-0 p-0 text-sm">
                                            <strong className="text-dark">Outside City Charge:</strong> &nbsp;
                                            {editMode === store.store_id ? (
                                                <input
                                                    type="text"
                                                    value={editableStore.store_ocb_charge}
                                                    onChange={(e) => handleChange('store_ocb_charge', e.target.value)}
                                                    className='edit-input'
                                                    style={{ width: '100px' }}
                                                />
                                            ) : (
                                                `$ ${store.store_ocb_charge}`
                                            )}
                                        </li>
                                        <li className="list-group-item border-0 p-0 text-sm">
                                            <strong className="text-dark">TelPay Name:</strong> &nbsp;
                                            {editMode === store.store_id ? (
                                                <input
                                                    type="text"
                                                    value={editableStore.telPay_Name}
                                                    onChange={(e) => handleChange('telPay_Name', e.target.value)}
                                                    className='edit-input'
                                                    style={{ width: '100px' }}
                                                />
                                            ) : (
                                                `"${store.telPay_Name}"`
                                            )}
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                                <div className="col-12 col-xl-3">
                                <div className="card card-plain h-100">
                                    <div className="card-header pb-0 p-3">
                                    <h6 className="mb-0">Store Fees</h6>
                                    <hr className="horizontal dark my-2" />
                                    <ul className="list-group ">
                                    
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Website Hosting Fee:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.website_hosting_fee}
                                                onChange={(e) => handleChange('website_hosting_fee', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `$ ${store.website_hosting_fee}`
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Fuel Surcharge:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.fuel_surcharge}
                                                onChange={(e) => handleChange('fuel_surcharge', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `$ ${store.fuel_surcharge}`
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Per order Fee Amount:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.per_order_fee}
                                                onChange={(e) => handleChange('per_order_fee', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `$ ${store.per_order_fee}`
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Tablet Rent Fee:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.tablet_rent_fee}
                                                onChange={(e) => handleChange('tablet_rent_fee', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `$ ${store.tablet_rent_fee}`
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Marketing Services Fee:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.marketing_services_fee}
                                                onChange={(e) => handleChange('marketing_services_fee', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `$ ${store.marketing_services_fee}`
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Address Correction Reminder Call Fee:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.address_correction_fee}
                                                onChange={(e) => handleChange('address_correction_fee', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `$ ${store.address_correction_fee}`
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Misc Charge:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.misc_charge}
                                                onChange={(e) => handleChange('misc_charge', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `$ ${store.misc_charge}`
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Misc Description:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.misc_description}
                                                onChange={(e) => handleChange('misc_description', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `"${store.misc_description}"`
                                        )}
                                    </li>
                                    </ul>
                                    </div>
                                    <div className="card-body p-3">
                                    
                                    </div>
                                </div>
                                </div>
                                <div className="col-12 col-xl-3">
                                <div className="card card-plain h-100">
                                    <div className="card-header pb-0 p-3">
                                    <h6 className="mb-0">Additional Details</h6>
                                    <hr className="horizontal dark my-2" />
                                    <ul className="list-group ">
                                    
                                    
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Reminder Twilio Phone Number:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.reminder_twilio_phone_number}
                                                onChange={(e) => handleChange('reminder_twilio_phone_number', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            store.reminder_twilio_phone_number
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Free Subscription Months:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.free_subsciption_period}
                                                onChange={(e) => handleChange('free_subsciption_period', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            store.free_subsciption_period
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">First Reminder Call Accept Fee:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.first_reminder_call_accept_fee}
                                                onChange={(e) => handleChange('first_reminder_call_accept_fee', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `$ ${store.first_reminder_call_accept_fee}`
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Second Reminder Call Accept Fee:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.second_reminder_call_accept_fee}
                                                onChange={(e) => handleChange('second_reminder_call_accept_fee', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `$ ${store.second_reminder_call_accept_fee}`
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Reminder Call Dispatch Accept Fee:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.reminder_call_dispatch_accept_fee}
                                                onChange={(e) => handleChange('reminder_call_dispatch_accept_fee', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `$ ${store.reminder_call_dispatch_accept_fee}`
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">First Reminder Call Request Fee:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.first_reminder_call_request_fee}
                                                onChange={(e) => handleChange('first_reminder_call_request_fee', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `$ ${store.first_reminder_call_request_fee}`
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Second Reminder Call Request Fee:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.second_reminder_call_request_fee}
                                                onChange={(e) => handleChange('second_reminder_call_request_fee', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `$ ${store.second_reminder_call_request_fee}`
                                        )}
                                    </li>
                                    <li className="list-group-item border-0 p-0 text-sm">
                                        <strong className="text-dark">Reminder Call Dispatch Request Fee:</strong> &nbsp;
                                        {editMode === store.store_id ? (
                                            <input
                                                type="text"
                                                value={editableStore.reminder_call_dispatch_request_fee}
                                                onChange={(e) => handleChange('reminder_call_dispatch_request_fee', e.target.value)}
                                                className='edit-input'
                                                style={{ width: '100px' }}
                                            />
                                        ) : (
                                            `$ ${store.reminder_call_dispatch_request_fee}`
                                        )}
                                    </li>
                                    </ul>
                                    </div>
                                    <div className="card-body p-3">
                                    
                                    </div>
                                </div>
                                </div>
                                
                            </div>
                        </div>
                        </td>
                    </tr>
                )}
                </>
            ))}
            </tbody>
        </table>
            </div>
            </div>
        </div>
      
    </div>
    </>
  )
}

export default Stores