import React, { useContext, useState, useEffect } from 'react'
import AuthContext from '../context/AuthContext';
import { API_ENDPOINTS } from '../settings';

const Header = ({ title }) => {
    const { user } = useContext(AuthContext)
    const [server_env, setServerEnv] = useState('');
    let [authTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
    useEffect(() => {
        fetch(API_ENDPOINTS.HEADER, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            }
        })
            .then(response => response.json())
            .then(data => setServerEnv(data.server_env));
    }, []);
  return (
    <>
    
    <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
      <div className="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <h6 className="font-weight-bolder mb-0">{title}</h6>
        </nav>
        <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
          <div className="ms-md-auto pe-md-3 d-flex align-items-center">
            
          </div>
          <ul className="navbar-nav  justify-content-end">
          <li className="nav-item d-flex align-items-center">
              <div className={`btn btn-sm mb-0 me-3 ${server_env !== 'DEV' ? 'btn-danger' : 'btn-outline-primary'}`}>{server_env}</div>
            </li>
            
            <li className="nav-item d-flex align-items-center">
              <div className="nav-link text-body font-weight-bold px-0">
                <i className="fa fa-user me-sm-1"></i>
                <span className="d-sm-inline d-none"> {user.username}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    </>
  )
}

export default Header