import React from 'react'
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import InvoiceDataGenerator from '../components/InvoiceDataGenerator';

const InvoiceDataGeneratorPage = () => {
    return (
        <>
        <SideBar />
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <Header title="Process Delivery Data" />
            
            <InvoiceDataGenerator />
        </main>
        </>
        
    );
    };

export default InvoiceDataGeneratorPage