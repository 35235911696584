import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  let {user}=useContext(AuthContext)
  if (!user) {
    return <Navigate to="/dashboard/login" state={{ from: location }} />;
  }
  return children;
};

export default PrivateRoute;