import React from 'react'
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import Invocie from '../components/Invocie';


const InvoicePage = () => {
    return (
        <>
        <SideBar />
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <Header title="Invoices" />
            
            <Invocie />
        </main>
        </>
        
    );
    };

export default InvoicePage