import React from 'react';
import './Footer.css'; // Import custom CSS

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        © 2024 Copyright:
        <a className="footer-link" href="https://www.iorders.ca/">iOrders.online</a>
      </div>
    </footer>
  );
}

export default Footer;